import React from "react"
import { MapPin, Mail } from "react-feather"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import helpers from "../styles/helpers.module.css"

const Contact = () => {
  return (
    <Layout page="Contact">
      <SEO title="Contact" />
      <div>
        <div className="container px-6 py-32 mx-auto sm:px-8 md:px-12 md:py-40 lg:px-24 lg:py-48 xl:py-56">
          <div className="xl:px-4">
            <p className={`${helpers.overline} mb-2`}>Get in touch</p>
            <h1 className="normal-case">
              Send an{" "}
              <span className="inline normal-case border-b-2 border-gray-900 border-solid">
                <a href="mailto:info@dryalda.co.uk">email</a>
              </span>
              , or leave a message in the{" "}
              <span className="inline normal-case border-b-2 border-gray-900 border-solid">
                <a href="#contactForm">contact form</a>
              </span>
              . View work on{" "}
              <span className="inline normal-case border-b-2 border-gray-900 border-solid">
                <a href="https://www.instagram.com/dryaldaclinics/?hl=en">
                  Instagram
                </a>
              </span>
              . See reviews on{" "}
              <span className="inline normal-case border-b-2 border-gray-900 border-solid">
                <a href="https://www.facebook.com/DrYalda.Aesthetics/">
                  Facebook
                </a>
              </span>
              .
            </h1>
          </div>
        </div>
      </div>
      <div className="bg-gray-900" id="contactForm">
        <div className="container px-6 py-16 mx-auto sm:py-24 sm:px-8 md:py-24 md:px-12 lg:px-12 lg:pb-56">
          <div className="xl:px-4">
            <div className="md:flex md:flex-wrap lg:flex-col lg:relative">
              <div className="md:order-1 md:w-1/2">
                <h3 className="mb-2 font-sans text-2xl font-semibold leading-none text-white md:text-left md:pr-10">
                  Dr Yalda would love to hear from you
                </h3>
                <p className="pb-8 text-white md:text-left md:pr-10 lg:pb-8 lg:pr-32">
                  If you&apos;d like to get in touch, please leave a message in
                  the form. Dr Yalda will respond to you within two days.
                </p>
              </div>
              <div className="md:mt-16 md:w-full md:order-3 lg:mt-0 lg:absolute lg:w-1/2 lg:top-0 lg:right-0">
                <form
                  className="w-full mx-auto md:mr-0 md:ml-0"
                  name="contact"
                  method="post"
                  action="/success"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="bot-field" />
                  <div className="flex flex-wrap mb-6 -mx-3">
                    <div className="w-full px-4 mb-6 md:w-1/2 lg:w-full">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-white uppercase sr-only"
                        htmlFor="first-name"
                      >
                        Your Name <p className="inline text-red-500">*</p>
                      </label>
                      <input
                        required
                        className="block w-full px-4 py-3 font-light leading-tight text-gray-900 bg-white border border-gray-900 appearance-none focus:outline-none focus:bg-white focus:border-blue-500"
                        id="first-name"
                        name="firstName"
                        type="text"
                        placeholder="Please enter your name"
                      />
                    </div>
                    <div className="w-full px-4 mb-6 md:w-1/2 lg:w-full">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-white uppercase sr-only"
                        htmlFor="last-name"
                      >
                        Email Address <p className="inline text-red-500">*</p>
                      </label>
                      <input
                        required
                        className="block w-full px-4 py-3 font-light leading-tight text-gray-900 bg-white border border-gray-900 appearance-none focus:outline-none focus:bg-white focus:border-blue-500"
                        id="last-name"
                        name="lastName"
                        type="text"
                        placeholder="Please enter your email address"
                      />
                    </div>
                    <div className="w-full px-4">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-white uppercase sr-only"
                        htmlFor="location"
                      >
                        Preferred location{" "}
                        <p className="inline text-red-500">*</p>
                      </label>
                      <select
                        className="block w-full px-4 py-3 font-light leading-tight text-gray-900 bg-white border border-gray-900 focus:outline-none focus:bg-white focus:border-blue-500"
                        id="location"
                        name="location"
                        placeholder="Preferred location"
                      >
                        <option value="">
                          Which location are you interested in?
                        </option>
                        <option value="nottingham">Nottingham</option>
                        <option value="manchester">Manchester</option>
                        <option value="leeds">Leeds</option>
                        <option value="online">Online Consultation</option>
                        <option value="none">None specific</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex flex-wrap w-full px-1 mb-6">
                    <div className="w-full">
                      <label
                        className="block mb-2 text-xs font-bold tracking-wide text-white uppercase sr-only"
                        htmlFor="message"
                      >
                        Message <p className="inline text-red-500">*</p>
                      </label>
                      <textarea
                        className="block w-full h-40 px-4 py-4 font-light leading-tight text-gray-900 bg-white border border-gray-900 focus:outline-none focus:bg-white focus:border-blue-500"
                        id="message"
                        name="message"
                        placeholder="Please leave your message here, be as brief or detailed as you like."
                      />
                    </div>
                  </div>
                  <button
                    className="flex-shrink-0 w-full px-6 py-4 mb-12 text-sm font-semibold shadow-sm text-darkText bg-mainBrand md:mb-0"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
              <div className="md:order-2 md:mb-4 md:w-1/2 md:pl-2 lg:pr-10 lg:pb-20 lg:pl-0">
                <div className="flex mb-4 align-center">
                  <MapPin className="inline mr-2 text-white" />
                  <p className="inline text-white">
                    <span className="font-semibold">Nottingham</span> 144 Forest
                    Road, Annesley, Kirkby-in-Ashfield, NG17 9HH
                  </p>
                </div>
                <div className="flex mb-4 align-center">
                  <MapPin className="inline mr-2 text-white" />
                  <p className="inline text-white">
                    <span className="font-semibold">Manchester</span> Serenity
                    Dental Spa, 264 Barlow Moor Road, M21 8HA
                  </p>
                </div>
                <div className="flex mb-4 align-center">
                  <MapPin className="inline mr-2 text-white" />
                  <p className="inline text-white">
                    <span className="font-semibold">Leeds</span> The Tower
                    Clinic, 8 Tinshill Lane, LS16 7AP
                  </p>
                </div>
                <div className="flex mb-4 align-center">
                  <Mail className="inline mr-2 text-white" />
                  <a href="mailto:info@dryalda.com" className="text-white">
                    info@dryalda.co.uk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        telephone
      }
    }
  }
`
